import { Workshop } from '@/types/app/workshop';
import { Project } from '@/types/app/project';

export default {
    dashboard: route('dashboard'),

    // workshop
    workshops: route('workshops.index'),
    workshop: {
        show: (workshop: Workshop) => route('workshops.show', { workshop: workshop.slug }),
        join: (workshop: Workshop) => route('workshops.enrolled.join', { workshop: workshop.slug }),

        enrolled: {
            list: route('workshops.enrolled.index'),
            show: (workshop: Workshop) => route('workshops.enrolled.show', { workshop: workshop.slug }),
            join: (workshop: Workshop) => route('workshops.enrolled.join', { workshop: workshop.slug }),
        }
    },

    //auth
    login: route('login'),
    register: route('register'),
    logout: route('logout'),
    socialLogin : (provider: string) => route('social.login', { provider }),
    // passwordRequest: route('password.request'),
    // passwordReset: route('password.reset'),
    // emailVerify: route('verification.notice'),
    // emailVerifySent: route('verification.sent'),
    // emailVerifyVerify: route('verification.verify'),

    // profile
    // profile: route('profile'),
    // profileEdit: route('profile.edit'),
    // profilePassword: route('profile.password'),


    // Instructor routes
    instructor: {
        join: "https://tally.so/r/mVNEkg",
        dashboard: route('instructor.dashboard'),
        workshops: {
            list: route('instructor.workshops.index'),
            create: route('instructor.workshops.create'),
            store: route('instructor.workshops.store'),
            edit: (workshop: Workshop) => route('instructor.workshops.edit', { workshop: workshop.slug }),
            update: (workshop: Workshop) => route('instructor.workshops.update', { workshop: workshop.slug }),
            publish: (workshop: Workshop) => route('instructor.workshops.publish', { workshop: workshop.slug }),
        } ,
        workshop: (workshop: Workshop) => route('instructor.workshops.show', { workshop: workshop.slug }),
        projects: {
            list: route('instructor.projects.index'),
            store: route('instructor.projects.store'),
            edit: (project: Project) => route('instructor.projects.edit', { project: project.id }),
            show: (project: Project) => route('instructor.projects.show', { project: project.id }),
            update: (project: Project) => route('instructor.projects.update', { project: project.id }),
            destroy: (project: Project) => route('instructor.projects.destroy', { project: project.id }),
        },
    }
}
